#When the window has finished loading create our google map below
google.maps.event.addDomListener window, 'load', ->

  # Basic options for a simple Google Map
  # For more options see: https:#developers.google.com/maps/documentation/javascript/reference#MapOptions
    mapOptions =
      # How zoomed in you want the map to start at (always required)
      zoom: 17,

      # The latitude and longitude to center the map (always required)
      center: new google.maps.LatLng(50.067716,14.432463),

      # How you would like to style the map.
      # This is where you would paste any style found on Snazzy Maps.
      styles: [{"featureType":"administrative","elementType":"labels.text.fill","stylers":[{"color":"#444444"}]},{"featureType":"landscape","elementType":"all","stylers":[{"color":"#f2f2f2"}]},{"featureType":"poi","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"poi","elementType":"labels.text","stylers":[{"visibility":"off"}]},{"featureType":"road","elementType":"all","stylers":[{"saturation":-100},{"lightness":45}]},{"featureType":"road.highway","elementType":"all","stylers":[{"visibility":"simplified"}]},{"featureType":"road.arterial","elementType":"labels.icon","stylers":[{"visibility":"off"}]},{"featureType":"transit","elementType":"all","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"all","stylers":[{"color":"#dbdbdb"},{"visibility":"on"}]}]


  # Get the HTML DOM element that will contain your map
  # We are using a div with id="map" seen below in the <body>
    mapElement = document.getElementById('map');

  # Create the Google Map using our element and options defined above
    map = new google.maps.Map(mapElement, mapOptions);

  # Let's also add a marker while we're at it
    marker = new google.maps.Marker
      position: new google.maps.LatLng(50.067716, 14.432463),
      map: map
      icon: "/assets/img/map-marker.png"
